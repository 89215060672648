/** @jsx jsx */
/* eslint-disable jsx-a11y/no-autofocus, react/jsx-no-target-blank */
import { jsx } from "theme-ui"
import React from "react"

import { rhythm } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <div style={{
        background: "#F3F5F7",
        height: "100%"
      }}>
        <div>
          <main
            sx={{
              mx: `auto`,
              maxWidth: rhythm(30),
              px: [2, 4],
              py: [3],
            }}
          >
            {children}
          </main>
        </div>
      </div>
    )
  }
}

export default Layout
